import React, { Component } from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';

const AnimationWrapper = styled.div`
	height: 100%;
	width: auto;
`;

class AnimationComponent extends Component {
	animation;

	componentDidMount() {
		const { animationSource, animationData, name } = this.props;

		if (animationSource) {
			this.animation = lottie.loadAnimation({
				wrapper: this.animationEl,
				animType: 'svg',
				autoplay: false,
				// prerender: true,
				loop: false,
				path: animationSource,
				name,
				// rendererSettings: {
				// 	progressiveLoad: true,
				// },
			});
		} else {
			this.animation = lottie.loadAnimation({
				wrapper: this.animationEl,
				animType: 'svg',
				autoplay: false,
				// prerender: true,
				loop: false,
				animationData,
				name,
				// rendererSettings: {
				// 	progressiveLoad: true,
				// },
			});
		}
	}

	render() {
		return <AnimationWrapper ref={node => (this.animationEl = node)} />;
	}
}

export default AnimationComponent;
