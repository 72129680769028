import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import ApiusLogo from '../../images/apius-logo.svg';

const Header = styled.h4`
	color: ${({ theme }) => theme.colors.white};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 30px;
	margin-bottom: 16px;
	@media (max-width: 767px) {
		padding-top: 34px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	@media (min-width: 451px) {
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}
	@media (min-width: 768px) {
		width: auto;
		flex-direction: column;
		justify-content: space-between;
		align-items: unset;
	}
`;

const WrapperBox = styled.div`
	display: flex;
	flex-direction: column;
	@media (min-width: 451px) {
		width: 50%;
	}
	@media (min-width: 768px) {
		width: auto;
		height: 138px;
	}
`;

const SocialMediaWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const SocialUrl = styled.a.attrs(props => {
	return {
		target: '_blank',
	};
})``;

const SocialIcon = styled.img`
	height: 24px;
	margin-right: 8px;
	width: 24px;
	&:nth-last-child {
		margin-right: 0;
	}
`;

const StyledApiusTextAndLogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 50px;
	text-decoration: none;
	@media (min-width: 451px) {
		margin-top: 34px;
	}
	@media (min-width: 768px) {
		margin-top: 0;
	}
	img {
		width: 118px;
		@media (min-width: 451px) {
			width: 85px;
		}
	}
	@media (min-width: 1440px) {
		display: none;
	}
`;

const StyledApiusText = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-size: 15px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 41px;
	@media (min-width: 451px) {
		font-size: 11px;
		line-height: 30px;
	}
`;

const FooterSocialMediaList = ({ data, currentLocale }) => {
	const moduleData = data?.moduleFooterSocialMediaList || '';

	let header;
	const socialMediaData = [];

	moduleData.map(item => {
		if (item.__typename === 'DatoCmsBlockHeader') {
			header = item?.header || '';
		} else if (item.__typename === 'DatoCmsBlockSocialMediaIcon') {
			socialMediaData.push({
				socialIcon: item?.icon?.url || '',
				socialIconAlt: item?.icon?.alt || '',
				socialUrl: item?.url || '',
			});
		}
	});
	return (
		<Wrapper>
			<WrapperBox>
				<Header dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
				<SocialMediaWrapper>
					{socialMediaData.map((item, key) => {
						return (
							item.socialIcon.length > 0 && (
								<SocialUrl href={item.socialUrl} key={key}>
									<SocialIcon src={item.socialIcon} alt={item.socialIconAlt} />
								</SocialUrl>
							)
						);
					})}
				</SocialMediaWrapper>
			</WrapperBox>
			<StyledApiusTextAndLogoWrapper
				as="a"
				target="_blank"
				href={currentLocale === 'pl' ? 'https://apius.pl/' : 'https://apius.pl/en'}
			>
				<StyledApiusText>{currentLocale === 'pl' ? 'Jesteśmy częścią' : 'We are part of'}</StyledApiusText>
				<img alt="logo" src={ApiusLogo} />
			</StyledApiusTextAndLogoWrapper>
		</Wrapper>
	);
};

export default FooterSocialMediaList;
