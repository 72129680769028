import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PageContainer from '../common/PageContainer';
import ShapeText from '../../images/rectangle-shape.svg';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const getHtmlFromRedactorField2 = field => {
	if (!field) {
		return null;
	}
	field = field.replace('<p>', '<p><span class="paragraf-start"></span>');
	field = field.replace('</p>', '<span class="paragraf-end"></span></p>');
	return {
		__html: `<p class="custom-redactor" style="display:block;" x-ms-format-detection="none">${field}</p>`,
	};
};

const TextWrapper = styled.div`
	@media (min-width: 1440px) {
		max-width: 40%;
	}
`;

const MainHeaderWrapper = styled.div`
	color: #191c63;
	font-size: 45px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	line-height: 45px;
	margin: 36px 0;
	strong {
		font-weight: ${({ theme }) => theme.fontWeight.extraBold};
	}
	@media (max-width: 350px) {
		font-size: 38px;
	}
	@media (min-width: 768px) {
		font-size: 60px;
		line-height: 60px;
		margin: 32px 0;
	}
	@media (min-width: 1440px) {
		font-size: 76px;
		line-height: 71px;
		margin: 40px 0;
	}
`;

const MainDescriptionWrapper = styled.p`
	color: #191c63;
	font-size: 16px;
	line-height: 27px;
	margin-bottom: 56px;
	@media (min-width: 768px) {
		margin-bottom: 64px;
	}
`;

const BoxWrapper = styled.div`
	border-radius: 25px;
	display: block;
	justify-content: space-between;
	margin-top: 80px;
	padding: 54px 24px 54px;
	text-align: center;
	width: 100%;
	&:nth-child(2) {
		background-color: #f1f2f3;
	}
	&:nth-child(3) {
		background-color: #9488ed;
	}
	&:nth-child(4) {
		background: linear-gradient(90deg, rgba(254, 56, 214, 1) 0%, rgba(242, 147, 19, 1) 100%);
	}

	@media (min-width: 1440px) {
		padding: 45px 24px 54px;
		&:nth-of-type(1) {
			margin-top: 125px;
		}
	}
`;

const SecondHeaderWrapper = styled.div`
	color: #fff;
	font-size: 40px;
	font-weight: lighter;
	line-height: 50px;
	&:nth-last-child(2) {
		color: #191c63;
	}

	@media (min-width: 768px) {
		font-size: 54px;
		line-height: 50px;
	}
	@media (min-width: 1440px) {
		font-weight: lighter;
	}
`;

const SecondDescriptionWrapper = styled.div`
	color: #191c63;
	font-size: 16px;
	font-weight: normal;
	line-height: 30px;

	.white {
		color: #fff;
	}
	@media (min-width: 768px) {
		margin-top: 10px;
	}
	@media (min-width: 1440px) {
	}
`;

const FeatureText = styled.div`
	color: #191c63;
	font-size: 14px;
	font-weight: regular;
	line-height: 20px;
	margin-top: 34px;
	position: relative;
	width: 100%;
	z-index: 10;

	.paragraf-start {
		display: inline-block;
		height: 1px;
		position: relative;
		width: 1px;

		&::before {
			background-image: url(${ShapeText});
			background-repeat: no-repeat;
			content: '';
			height: 50px;
			position: absolute;
			transform: translate(-18px, -26px);
			width: 50px;
			z-index: -2;
		}
	}

	@media (min-width: 768px) {
		width: 48%;
	}
	@media (min-width: 1440px) {
		font-size: 16px;
		line-height: 30px;
		width: 22%;
	}
`;

const FeatureWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (min-width: 768px) {
		flex-wrap: wrap;
		flex-direction: row;
	}
	@media (min-width: 1440px) {
		justify-content: space-around;
		align-items: start;
	}
`;

const Image = styled.div`
	display: block;
	margin: 0 auto;
	margin: 0 auto;
	position: absolute;
	transform: translate(0, -112px);
	&.basic {
		height: 82px;
		right: 0;
		transform: translate(0, -98px);
		width: 144px;
	}
	&.standard {
		height: 224px;
		left: -70px;
		transform: translate(0, -104px);
		width: 169px;
	}
	&.premium {
		height: 148px;
		right: -64px;
		transform: translate(0, -120px) scale(0.7);
		width: 252px;
	}
	@media (min-width: 768px) {
		&.basic {
			right: 20%;
			width: 144px;
			height: 82px;
			transform: translate(0, -98px);
		}
		&.standard {
			left: 6%;
			width: 169px;
			height: 224px;
			transform: translate(0, -104px);
		}
		&.premium {
			right: -5%;
			width: 252px;
			height: 148px;
			transform: translate(0, -120px) scale(0.8);
		}
	}
	@media (min-width: 1440px) {
		&.basic {
			right: 30%;
			transform: translate(0, -90px);
		}
		&.standard {
			left: 23%;
			transform: translate(0, -79px);
		}
		&.premium {
			right: 15%;
			transform: translate(0, -77px);
		}
	}
`;

const SubscriptionPlan = ({ data, elementId }) => {
	const moduleData = data?.moduleSubscriptionPlan || '';
	const headersAndDescripionsArray = [];
	let mainHeader = '';
	let mainDescription = '';

	moduleData.map(item => {
		if (item.__typename === 'DatoCmsBlockHeaderDescription') {
			mainHeader = item?.header || '';
			mainDescription = item?.description;
		} else if (item.__typename === 'DatoCmsBlockHeaderSubheaderListIcon') {
			headersAndDescripionsArray.push({
				secondaryHeader: item?.header || '',
				secondaryDescription: item?.subheader || '',
				Feature1: item?.description1 || '',
				Feature2: item?.description2 || '',
				Feature3: item?.description3 || '',
				Feature4: item?.description4 || '',
				iconImg: item?.icon?.fluid || '',
				iconAlt: item?.icon?.alt || '',
			});
		}
	});

	const newFunction = key => {
		if (key === 0) {
			return 'basic';
		}
		if (key === 1) {
			return 'standard';
		}
		return 'premium';
	};

	return (
		<PageContainer id={elementId}>
			<TextWrapper>
				<MainHeaderWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(mainHeader)} />
				<MainDescriptionWrapper>{mainDescription}</MainDescriptionWrapper>
			</TextWrapper>

			{headersAndDescripionsArray.map((item, key) => {
				const { iconImg, secondaryHeader, secondaryDescription, iconAlt, Feature1, Feature2, Feature3, Feature4 } = item;

				return (
					<BoxWrapper key={Feature1}>
						{iconImg && (
							<Image className={newFunction(key)}>
								<Img fluid={iconImg} alt={iconAlt} imgStyle={{ objectFit: 'unset' }} />
							</Image>
						)}
						<SecondHeaderWrapper className="white" dangerouslySetInnerHTML={getHtmlFromRedactorField(secondaryHeader)} />
						{secondaryDescription && (
							<SecondDescriptionWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(secondaryDescription)} />
						)}
						<FeatureWrapper className="feature-wrapper">
							{Feature1 && <FeatureText dangerouslySetInnerHTML={getHtmlFromRedactorField2(Feature1)} />}
							{Feature2 && <FeatureText dangerouslySetInnerHTML={getHtmlFromRedactorField2(Feature2)} />}
							{Feature3 && <FeatureText dangerouslySetInnerHTML={getHtmlFromRedactorField2(Feature3)} />}
							{Feature4 && <FeatureText dangerouslySetInnerHTML={getHtmlFromRedactorField2(Feature4)} />}
						</FeatureWrapper>
					</BoxWrapper>
				);
			})}
		</PageContainer>
	);
};

export default SubscriptionPlan;
