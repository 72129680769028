import React, { Component } from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';
import ArrowDown from '../../images/arrow-down.svg';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 56px;
	position: relative;
	width: 100%;
	z-index: 10;

	@media (min-width: 1440px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const TextWrapper = styled.div`
	display: block;
	width: 100%;
	@media (min-width: 1440px) {
		width: 45%;
	}
`;

const HeaderWrapper = styled.div`
	color: #191c63;
	font-size: 22px;
	font-weight: normal;
	line-height: 30px;
	margin: 36px 0;
	@media (min-width: 768px) {
		font-size: 24px;
		margin: 32px 0;
	}
	@media (min-width: 1440px) {
		font-size: 31px;
		margin: 40px 0;
	}

	div h1 {
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
		margin: inherit;
	}
`;

const DescriptionWrapper = styled.div`
	color: #191c63;
	font-size: 16px;
	line-height: 27px;
	margin-bottom: 56px;
	@media (min-width: 768px) {
		margin-bottom: 64px;
	}
`;

const StyledRowWrapper = styled.div`
	border-spacing: unset;
	width: 100%;
	@media (min-width: 1440px) {
		width: 50%;
	}
`;

const RowWrapper = styled.div`
	margin-bottom: 8px;
`;
const Row = styled.div`
	align-items: center;
	background-color: #f7f7f7;
	display: flex;
	justify-content: space-between;
	padding: 22px 16px;

	div {
		color: #191c63;
		font-size: 14px;
		font-weight: 700;
	}

	&.pointer {
		cursor: pointer;
	}
	&.active {
		font-weight: bold;
	}
`;

const RowExtended = styled.div`
	max-height: 1px;
	opacity: 0;
	overflow-y: hidden;
	transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
	&.open {
		max-height: 500px;
		opacity: 1;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}
	div {
		border: none;
	}
`;

const ExtendedContentInfo = styled.div`
	background-color: #f7f7f7;
	color: #191c63;
	font-size: 16px;
	line-height: 27px;
	padding: 0px 16px 22px;
`;

const StyledIconWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: auto;
`;

const StyledIcon = styled.img`
	cursor: pointer;
	height: 10px;
	transform: rotate(0deg);
	transition: transform 0.3s ease-in-out;
	width: 20px;
	&.rotated {
		transform: rotate(180deg);
	}
`;

const Image = styled.img`
	display: block;
	height: 45px;
	width: 45px;
`;

class DropdownList extends Component {
	state = {
		activeIndex: null,
	};

	toggleDropdownExpandability = (rowId, arrowId) => {
		const clickedItem = document.getElementById(rowId);
		const clickedItemArrow = document.getElementById(arrowId);
		if (clickedItem.classList.contains('open')) {
			clickedItem.classList.remove('open');
		} else {
			clickedItem.classList.add('open');
		}
		if (clickedItemArrow.classList.contains('rotated')) {
			clickedItemArrow.classList.remove('rotated');
		} else {
			clickedItemArrow.classList.add('rotated');
		}
	};

	render() {
		const { activeIndex } = this.state;
		const { data } = this.props;

		const AccordionId = data?.constantId;
		const descHeaderIconModuleArray = [];
		const headerTextModuleArray = [];
		const moduleDropdownListArray = data?.moduleDropdownList || '';

		moduleDropdownListArray.forEach(item => {
			if (item.__typename === 'DatoCmsBlockIconHeaderDescription') {
				descHeaderIconModuleArray.push(item);
			} else if (item.__typename === 'DatoCmsBlockDropdownElement') {
				headerTextModuleArray.push(item);
			}
		});

		const classContainer = (() => {
			if (AccordionId === 5346121) {
				return 'shape1';
			}
			if (AccordionId === 5346122) {
				return '';
			}
			if (AccordionId === 5346123) {
				return '';
			}
			if (AccordionId === 5346124) {
				return 'shape2';
			}
			if (AccordionId === 5346125) {
				return 'illustration';
			}
			return '';
		})();

		return (
			<PageContainer className={classContainer}>
				<ModuleWrapper>
					<TextWrapper>
						{descHeaderIconModuleArray.map((item, key) => {
							const header = item?.header || '';
							const description = item?.description || '';
							const icon = item?.icon?.url || '';
							const iconAlt = item?.icon?.alt || '';
							return (
								<React.Fragment key={header}>
									<Image src={icon} alt={iconAlt} />
									<HeaderWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
									<DescriptionWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
								</React.Fragment>
							);
						})}
					</TextWrapper>
					<StyledRowWrapper>
						<div>
							{headerTextModuleArray.map((elementArray, key) => {
								const elementId = AccordionId + key;
								const rowExpandedId = `${AccordionId}-row-expanded-${key}`;
								const arrowImageId = `${AccordionId}-arrow-${key}`;
								const accordionHeader = elementArray?.header || '';
								const accordionText = elementArray?.text || '';
								return (
									<React.Fragment key={elementId}>
										<RowWrapper>
											<Row
												id={elementId}
												className={`${activeIndex === key ? 'active ' : ''}pointer`}
												onClick={() => this.toggleDropdownExpandability(rowExpandedId, arrowImageId)}
											>
												<div dangerouslySetInnerHTML={getHtmlFromRedactorField(accordionHeader)} />
												<StyledIconWrapper>
													<StyledIcon
														id={arrowImageId}
														src={ArrowDown}
														className={key === activeIndex ? 'rotated' : 'normal'}
													/>
												</StyledIconWrapper>
											</Row>
											<RowExtended id={rowExpandedId}>
												<ExtendedContentInfo>{accordionText}</ExtendedContentInfo>
											</RowExtended>
										</RowWrapper>
									</React.Fragment>
								);
							})}
						</div>
					</StyledRowWrapper>
				</ModuleWrapper>
			</PageContainer>
		);
	}
}

export default DropdownList;
