import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ExtendedContent from '../components/extendedContent';
import ExtendedContentFooter from '../components/extendedContentFooter';
import PageContainer from '../components/common/PageContainer';
import Seo from '../components/seo/Seo';
import Menu from '../components/menu/Menu';
import ApiusLogo from '../images/apius-logo.svg';

const Footer = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.navyBlue};
	display: flex;
	flex-direction: column;
	height: auto;
	justify-content: center;
	padding: 75px 0;
	position: relative;
	width: 100%;
	z-index: 10;
`;
const LogoWrapper = styled.a`
	bottom: 20px;
	left: 25px;
	position: absolute;
`;

const LogoImage = styled.div`
	height: 15px;
	width: 52px;
`;

const StyledApiusTextAndLogoWrapper = styled.div`
	img {
		width: 85px;
	}
	display: none;
	@media (min-width: 1440px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin-right: 75px;
		padding-top: 18px;
		text-decoration: none;
		cursor: pointer;
	}
`;

const StyledApiusText = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-size: 15px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 41px;
	@media (min-width: 451px) {
		font-size: 11px;
		line-height: 30px;
	}
`;

const HomePage = ({ data: { allHomeData, byPleoLogoImg }, pageContext }) => {
	const currentLocale = pageContext?.locale;
	const allModules = allHomeData?.nodes?.[0]?.chooseModule || '';
	const footerModules = allHomeData?.nodes?.[0]?.chooseFooterModule || '';
	const seoData = allHomeData.nodes[0].seoSettings || '';
	const byPleoLogo = byPleoLogoImg?.childImageSharp?.fluid || '';

	return (
		<>
			<Seo seoData={seoData} />
			<Menu currentLocale={currentLocale} />
			<ExtendedContent data={allModules} />
			<Footer>
				<PageContainer className="footer">
					<StyledApiusTextAndLogoWrapper
						as="a"
						target="_blank"
						href={currentLocale === 'pl' ? 'https://apius.pl/' : 'https://apius.pl/en'}
					>
						<StyledApiusText>{currentLocale === 'pl' ? 'Jesteśmy częścią' : 'We are part of'}</StyledApiusText>
						<img alt="logo" src={ApiusLogo} />
					</StyledApiusTextAndLogoWrapper>
					<ExtendedContentFooter data={footerModules} currentLocale={currentLocale} />
				</PageContainer>
				<LogoWrapper href="https://www.pleodigital.com/" target="_blank">
					<LogoImage>
						<Img fluid={byPleoLogo} imgStyle={{ objectFit: 'unset' }} />
					</LogoImage>
				</LogoWrapper>
			</Footer>
		</>
	);
};

export default HomePage;

export const query = graphql`
	query($locale: String) {
		byPleoLogoImg: file(relativePath: { eq: "by-pleo-logo.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
		allHomeData: allDatoCmsHome(filter: { locale: { eq: $locale } }) {
			nodes {
				__typename
				... on DatoCmsHome {
					seoSettings {
						title
						description
						twitterCard
						image {
							url
						}
					}
					chooseModule {
						__typename
						... on DatoCmsModuleHeaderDescription {
							moduleHeaderDescription {
								header
								description
							}
						}
						... on DatoCmsModuleHeaderImage {
							moduleHeaderImage {
								header
								image {
									alt
									fluid {
										...GatsbyDatoCmsFluid_noBase64
									}
								}
							}
						}
						... on DatoCmsModuleTileListImage {
							moduleTileListImage {
								__typename
								... on DatoCmsBlockHeader {
									header
								}
								... on DatoCmsBlockTile {
									image {
										alt
										fluid {
											...GatsbyDatoCmsFluid_noBase64
										}
									}
									imageMobile {
										alt
										fluid {
											...GatsbyDatoCmsFluid_noBase64
										}
									}
									list
								}
							}
						}
						... on DatoCmsModuleSubscriptionPlan {
							moduleSubscriptionPlan {
								__typename
								... on DatoCmsBlockHeaderDescription {
									header
									description
								}
								... on DatoCmsBlockHeaderSubheaderListIcon {
									icon {
										alt
										fluid {
											...GatsbyDatoCmsFluid_noBase64
										}
									}
									header
									subheader
									description1
									description2
									description3
									description4
								}
							}
						}
						... on DatoCmsModuleBanner {
							moduleBanner {
								header
								description
								image {
									alt
									fluid(maxWidth: 3000) {
										...GatsbyDatoCmsFluid_noBase64
									}
								}
							}
						}
						... on DatoCmsModuleHeaderDescriptionButton {
							moduleHeaderDescriptionButton {
								__typename
								... on DatoCmsBlockHeaderDescriptionButton {
									header
									description
									textOnButton
								}
							}
						}
						... on DatoCmsModuleHeaderDescriptionAnimation {
							moduleHeaderDescriptionAnimation {
								header
								description
								animation {
									url
								}
							}
						}
						... on DatoCmsModuleSlider {
							moduleSlider {
								__typename
								... on DatoCmsBlockHeader {
									header
								}
								... on DatoCmsBlockSlide {
									#									image {
									#										url
									#									}
									#									video {
									#										url
									#									}
									url
								}
							}
						}
						... on DatoCmsModuleImageList {
							moduleImageList {
								__typename
								... on DatoCmsBlockHeader {
									header
								}
								... on DatoCmsBlockImage {
									animationMobile {
										url
									}
									animationTablet {
										url
									}
									animationDesktop {
										url
									}
								}
								... on DatoCmsBlockListItem {
									text
								}
							}
						}
						... on DatoCmsModuleHeaderDescriptionLogo {
							moduleHeaderDescriptionLogo {
								__typename
								... on DatoCmsBlockHeader {
									header
								}
								... on DatoCmsBlockDescription {
									text
								}
								... on DatoCmsBlockLogo {
									logo {
										alt
										url
									}
								}
							}
						}
						... on DatoCmsModuleOpinionClient {
							id
							header
							maleAvatar {
								fluid(maxWidth: 100, maxHeight: 100) {
									...GatsbyDatoCmsFluid
								}
								url
							}
							femaleAvatar {
								fluid(maxWidth: 100, maxHeight: 100) {
									...GatsbyDatoCmsFluid
								}
								url
							}
							slideOpinion {
								__typename
								id
								logo {
									fluid(maxWidth: 150, maxHeight: 150) {
										...GatsbyDatoCmsFluid
									}
									url
								}
								opinionText
								authorCompany
								authorName
								isfemaleavatar
							}
						}
						... on DatoCmsModuleDropdownList {
							constantId
							moduleDropdownList {
								__typename
								... on DatoCmsBlockIconHeaderDescription {
									icon {
										alt
										url
									}
									header
									description
								}
								... on DatoCmsBlockDropdownElement {
									header
									text
								}
							}
						}

						... on DatoCmsModuleDescriptionPoint {
							moduleDescriptionPoint {
								__typename
								... on DatoCmsBlockDescription {
									text
								}
								... on DatoCmsBlockPlanPoint {
									point
								}
							}
						}
						... on DatoCmsModuleHeaderPointsList {
							moduleHeaderPointsList {
								__typename
								... on DatoCmsBlockHeader {
									header
								}
								... on DatoCmsBlockPoint {
									title
									description
								}
							}
						}
					}
					chooseFooterModule {
						__typename
						... on DatoCmsModuleFooterHeaderDescription {
							moduleFooterHeaderDescription {
								__typename
								... on DatoCmsBlockFooterHeaderDescription {
									header
									description
								}
							}
						}
						... on DatoCmsModuleFooterSocialMediaList {
							moduleFooterSocialMediaList {
								__typename
								... on DatoCmsBlockHeader {
									header
								}
								... on DatoCmsBlockSocialMediaIcon {
									icon {
										alt
										url
									}
									url
								}
							}
						}
					}
				}
			}
		}
	}
`;
