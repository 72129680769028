import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PageContainer from '../common/PageContainer';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { RedactorHeader } from '../common/RedactorHeader';
import ListStyleTypeDot from '../../images/ul-style-type.png';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-x: hidden;
	width: 100%;
`;

const TiltsWrapper = styled.div`
	margin-top: 80px;
	@media (min-width: 768px) {
		margin-top: 120px;
	}
	@media (min-width: 1440px) {
		margin-top: 94px;
	}
`;

const SingleTiltWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.tiltWhiteOpacity0_08};
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 65px;
	padding: 60px 15px 10px 45px;
	position: relative;
	width: 100%;
	@media (min-width: 425px) {
		padding: 40px 20px 10px 50px;
	}
	@media (min-width: 768px) {
		margin-bottom: 75px;
		padding: 0 20px 50px 70px;
	}
	@media (min-width: 1440px) {
		margin-bottom: 67px;
		justify-content: space-between;
		&:nth-child(odd) {
			flex-direction: row;
			padding: 0px 20px;
			.image-wrapper {
				width: 35%;
				margin-left: -30px;
			}
			.list-wrapper {
				width: 65%;
			}
		}
		&:nth-child(even) {
			flex-direction: row-reverse;
			padding: 10px 120px;
			.image-wrapper {
				width: 25%;
				margin-left: 0;
				margin-right: -30px;
			}
			.list-wrapper {
				width: 75%;
			}
		}
	}
	ul {
		li {
			color: ${({ theme }) => theme.colors.white};
			font-size: 14px;
			font-weight: ${({ theme }) => theme.fontWeight.regular};
			line-height: 20px;
			margin-bottom: 15px;
			position: relative;
			@media (min-width: 425px) {
				margin-bottom: 20px;
			}
			@media (min-width: 768px) {
				font-size: 17px;
				line-height: 34px;
				margin-bottom: 0;
			}
			@media (min-width: 1440px) {
				line-height: 45px;
			}
			&:before {
				background-image: url(${ListStyleTypeDot});
				content: '';
				height: 20px;
				left: -35px;
				position: absolute;
				width: 20px;
				@media (min-width: 768px) {
					top: 7px;
				}
				@media (min-width: 1440px) {
					top: 12px;
				}
			}
		}
	}
`;

const ListWrapper = styled.div`
	width: 100%;
	@media (min-width: 1440px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

const ImageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-left: -15px;
	width: 100%;
	@media (min-width: 425px) {
		margin-top: 20px;
	}
	@media (min-width: 1440px) {
		transform: translateY(-20%);
	}
`;

const TiltImage = styled.div`
	display: none;
	z-index: 10;
	.gatsby-image-wrapper {
		height: 100%;
		width: 100%;
	}
	@media (min-width: 768px) {
		display: block;
		height: 200px;
		width: 189px;
		margin: -60px auto 0;
	}
	@media (min-width: 1440px) {
		width: 244px;
		height: 244px;
		margin: 0;
	}
	&.mobile {
		display: block;
		height: 120px;
		margin: -110px auto 0;
		max-width: 100%;
		width: 120px;
		@media (min-width: 425px) {
			height: 140px;
			width: 140px;
		}
		@media (min-width: 768px) {
			display: none;
		}
	}
`;

const TileListImage = ({ data }) => {
	const moduleData = data?.moduleTileListImage || '';

	let header = '';
	const tiltsArray = [];
	moduleData.map(item => {
		if (item.__typename === 'DatoCmsBlockHeader') {
			header = item?.header || '';
		} else if (item.__typename === 'DatoCmsBlockTile') {
			tiltsArray.push({
				image: item?.image?.fluid || '',
				imageAlt: item?.image?.alt || '',
				imageMobile: item?.imageMobile?.fluid || '',
				imageMobileAlt: item?.imageMobile?.alt || '',
				list: item?.list || '',
			});
		}
	});

	return (
		<PageContainer>
			<ModuleWrapper>
				<RedactorHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
				<TiltsWrapper>
					{tiltsArray.map(item => {
						const { image, imageAlt, imageMobile, imageMobileAlt, list } = item;

						return (
							<SingleTiltWrapper key={list}>
								<ImageWrapper className="image-wrapper">
									<TiltImage>
										<Img fluid={image} alt={imageAlt} imgStyle={{ objectFit: 'unset' }} />
									</TiltImage>
									<TiltImage className="mobile">
										<Img fluid={imageMobile} alt={imageMobileAlt} imgStyle={{ objectFit: 'unset' }} />
									</TiltImage>
								</ImageWrapper>
								<ListWrapper className="list-wrapper" dangerouslySetInnerHTML={getHtmlFromRedactorField(list)} />
							</SingleTiltWrapper>
						);
					})}
				</TiltsWrapper>
			</ModuleWrapper>
		</PageContainer>
	);
};

export default TileListImage;
