import React from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';
import GreyBg from '../../images/gray-bg.png';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const ModuleWrapper = styled.div`
	color: ${({ theme }) => theme.colors.navyBlue};
	margin-bottom: 110px;
	margin-top: 80px;
	position: relative;
	@media (min-width: 1440px) {
		margin-top: 130px;
	}
`;

const MainHeaderWrapper = styled.div`
	color: #191c63;
	font-size: 45px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	line-height: 45px;
	padding: 36px 0;
	strong {
		font-weight: ${({ theme }) => theme.fontWeight.extraBold};
	}
	@media (min-width: 400px) {
		padding: 40px 0 32px;
	}
	@media (min-width: 500px) {
		padding: 60px 0 32px;
	}
	@media (min-width: 768px) {
		font-size: 60px;
		line-height: 60px;
		padding: 100px 0 32px;
	}
	@media (min-width: 1440px) {
		font-size: 76px;
		line-height: 71px;
		padding: 180px 0 24px;
	}
`;

const DescriptionWrapper = styled.div`
	color: #191c63;
	font-size: 16px;
	line-height: 27px;
	margin-bottom: 56px;
	@media (min-width: 768px) {
		margin-bottom: 64px;
	}
`;

const ImagesWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
`;

const GreyBgBox = styled.div`
	background-image: url(${GreyBg});
	background-repeat: no-repeat;
	height: 100%;
	position: absolute;
	width: 200%;
	z-index: 0;
	@media (min-width: 375px) {
		top: 200px;
		left: -200px;
		background-size: 702px;
	}
	@media (min-width: 768px) {
		top: 156px;
		left: calc(100% - 352px);
		background-size: 800px;
	}
	@media (min-width: 1440px) {
		top: 89px;
		left: calc(100% - 541px);
		background-size: 857px;
		transform: scale(0.85);
	}
`;

const LogoBox = styled.div`
	@media (min-width: 320px) {
		width: 130px;
		height: auto;
		margin: 16px 0px;
		padding: 0 8px;
	}
	@media (min-width: 375px) {
		width: 130px;
		height: auto;
		margin: 16px 8px;
		padding: 0;
	}
	@media (min-width: 710px) {
		width: 150px;
	}

	@media (min-width: 768px) {
		background-position: center;
		height: auto;
		width: 200px;
		margin: 16px 0;
	}
`;

const LogoImg = styled.img`
	display: flex;
	flex-wrap: wrap;
	height: auto;
	margin: 16px 0;
	max-width: 100%;
`;

const HeaderDescriptionLogo = ({ data, elementId }) => {
	const moduleData = data?.moduleHeaderDescriptionLogo || '';
	let header = '';
	let description = '';
	const logoArray = [];
	moduleData.map(item => {
		if (item.__typename === 'DatoCmsBlockHeader') {
			header = item?.header || '';
		}
		if (item.__typename === 'DatoCmsBlockDescription') {
			description = item?.text || '';
		}
		if (item.__typename === 'DatoCmsBlockLogo') {
			logoArray.push({
				logoImg: item?.logo?.url || '',
				logoAlt: item?.logo?.alt || '',
			});
		}
	});

	return (
		<PageContainer id={elementId}>
			<ModuleWrapper>
				<GreyBgBox />
				<MainHeaderWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
				<DescriptionWrapper>{description}</DescriptionWrapper>
				<ImagesWrapper>
					{logoArray.map(item => {
						return (
							<LogoBox key={item.logoImg}>
								<LogoImg src={item.logoImg} alt={item.logoAlt} />
							</LogoBox>
						);
					})}
				</ImagesWrapper>
			</ModuleWrapper>
		</PageContainer>
	);
};

export default HeaderDescriptionLogo;
