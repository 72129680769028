import React from 'react';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';

const SingleSlideVideo = styled.div`
	border-radius: 20px !important;
	height: auto;
	margin: 0 auto;
	width: 100%;
	iframe {
		border-radius: 20px !important;
	}
	@media (min-width: 375px) {
		width: 315px;
	}
	@media (min-width: 500px) {
		width: 460px;
	}
	@media (min-width: 580px) {
		width: 522px;
	}
	@media (min-width: 768px) {
		width: 522px;
	}
	@media (min-width: 1440px) {
		width: 936px;
	}
`;

const VideoModule = ({ videoUrl, newKey, paused }) => {
	const getQueryParams = (params, url) => {
		const href = url;
		const reg = new RegExp(`[?&#]${params}=([^&#]*)`, 'i');
		const queryString = reg.exec(href);
		return queryString ? queryString[1] : null;
	};

	const startTime = getQueryParams('t', videoUrl);
	const startTimeNumb = parseInt(startTime?.match(/\d/g).join(''), 10) || 0;
	return (
		<>
			<SingleSlideVideo id={newKey} key={videoUrl} style={{ display: paused ? 'none' : 'block' }}>
				<Vimeo
					video={videoUrl}
					width="100%"
					start={startTimeNumb}
					paused={paused}
					controls
					responsive
					loop={false}
					volume={1}
					quality={240}
				/>
			</SingleSlideVideo>
		</>
	);
};

export default VideoModule;
