import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PageContainer from '../common/PageContainer';
import { RedactorHeader } from '../common/RedactorHeader';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const ModuleWrapper = styled.div`
	margin: 150px 0 60px;
`;

const BackgroundContainer = styled.div`
	background: transparent linear-gradient(180deg, #fa50a3 0%, #f19a04 100%) 0 0 no-repeat;
	border-radius: 25px;
	height: 1520px;
	left: 0;
	position: absolute;
	top: -70px;
	width: 100%;
	z-index: -2;
	@media (min-width: 375px) {
		height: 1320px;
	}
	@media (min-width: 768px) {
		height: 1201px;
		width: 720px;
		left: calc((100% - 720px) / 2);
	}
	@media (min-width: 1440px) {
		width: 1266px;
		height: 1250px;
		left: calc((100% - 1266px) / 2);
	}
	&.mobile {
		@media (min-width: 768px) {
			display: none;
		}
	}
	&.tablet {
		display: none;
		@media (min-width: 768px) {
			display: block;
		}
		@media (min-width: 1440px) {
			display: none;
		}
	}
	&.desktop {
		display: none;
		@media (min-width: 1440px) {
			display: block;
		}
	}
`;

const PlanetWrapper = styled.div`
	height: 135px;
	opacity: 0.41;
	position: absolute;
	right: -30px;
	top: -10px;
	width: 135px;
	@media (min-width: 768px) {
		right: 50px;
	}
	@media (min-width: 1440px) {
		right: 200px;
	}
`;

const PointsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 45px auto 0;
	width: 100%;
	@media (min-width: 1440px) {
		flex-direction: row;
		justify-content: space-between;
		margin-top: 80px;
	}
`;

const SinglePointWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto;
	max-width: 315px;
	width: 100%;
	@media (min-width: 768px) {
		max-width: 700px;
	}
`;

const DotImage = styled.div`
	height: 36px;
	margin: 0 auto;
	width: 36px;
`;

const Title = styled.h3`
	color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	font-size: 22px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	justify-content: flex-start;
	line-height: 29px;
	margin: 20px 0;
	padding: 0 40px;
	text-align: center;
	@media (min-width: 768px) {
		margin-bottom: 0;
		padding: 0;
	}
	@media (min-width: 1440px) {
		font-size: 30px;
		line-height: 39px;
		min-height: 120px;
		justify-content: center;
		margin-bottom: 20px;
	}
`;

const Description = styled.p`
	color: ${({ theme }) => theme.colors.white};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 27px;
	margin-bottom: 30px;
	padding: 0 10px;
	text-align: center;
	@media (min-width: 1440px) {
		font-size: 18px;
		line-height: 34px;
	}
`;

const HeaderAndPoints = ({ data, images }) => {
	const moduleData = (data && data.moduleHeaderPointsList) || [];
	const headerArray = [];
	const titleDescriptionArray = [];
	const borderImg = images?.borderImg?.childImageSharp?.fluid || '';
	const planetImg = images?.planetImg?.childImageSharp?.fluid || '';

	moduleData.forEach(item => {
		if (item.__typename === 'DatoCmsBlockHeader') {
			headerArray.push({
				header: item?.header || '',
			});
		} else if (item.__typename === 'DatoCmsBlockPoint') {
			titleDescriptionArray.push({
				title: item?.title || '',
				description: item?.description || '',
			});
		}
	});

	return (
		<PageContainer>
			<BackgroundContainer />
			<ModuleWrapper>
				<PlanetWrapper>
					<Img fluid={planetImg} imgStyle={{ objectFit: 'unset' }} />
				</PlanetWrapper>
				{headerArray.map((item, key) => {
					const header = item?.header || '';
					return <RedactorHeader key={key} className="navy-blue" dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />;
				})}
				<PointsWrapper>
					{titleDescriptionArray.map((item, key) => {
						const { title } = item;
						const { description } = item;
						return (
							<SinglePointWrapper key={key}>
								<DotImage>
									<Img fluid={borderImg} imgStyle={{ objectFit: 'unset' }} />
								</DotImage>
								<Title>{title}</Title>
								<Description>{description}</Description>
							</SinglePointWrapper>
						);
					})}
				</PointsWrapper>
			</ModuleWrapper>
		</PageContainer>
	);
};

export default props => {
	return (
		<StaticQuery
			query={graphql`
				query {
					borderImg: file(relativePath: { eq: "IsForYouIf/white-dot-pink-border.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					planetImg: file(relativePath: { eq: "IsForYouIf/planet.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			`}
			render={data => <HeaderAndPoints images={data} {...props} />}
		/>
	);
};
