import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const StyledWrapperSlide = styled.div`
	//align-items: center;
	display: flex;
	flex-direction: column;
	height: 406px;
	justify-content: center;
	margin-top: 20px;
	max-width: 900px;
	text-align: center;
	@media (min-width: 500px) {
		margin-top: 10px;
		padding: 0px 65px;
	}
	@media (min-width: 1300px) {
		margin-top: 40px;
		padding: 0px 20px;
	}
`;
const DescriptionWrapper = styled.div`
	color: #191c63;
	font-size: 22px;
	margin-bottom: 25px;
`;
const StyledAuthorName = styled.div`
	color: #191c63;
	font-size: 18px;
	font-weight: bold;
`;
const StyledAuthorCompany = styled.div`
	color: #191c63;
	font-size: 18px;
`;
const StyledWrapperImg = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	margin: 0 auto;
	max-height: 150px;
	max-width: 150px;
	width: 100%;
`;
const StyledImg = styled.div`
	height: auto;
	width: 100%;
`;

class HomeSlide extends React.Component {
	render() {
		const { singleItemData, logoMale, logoFemale } = this.props;
		const description = singleItemData.opinionText;
		const isFemaleAvatar = singleItemData?.isfemaleavatar || '';
		const Logo = singleItemData?.logo?.fluid || '';

		return (
			<>
				<StyledWrapperSlide>
					<StyledWrapperImg>
						<StyledImg>
							{Logo && <Img fluid={Logo} alt={Logo} />}
							{!Logo && isFemaleAvatar && <Img fluid={logoFemale} alt={Logo} />}
							{!Logo && !isFemaleAvatar && <Img fluid={logoMale} alt={Logo} />}
						</StyledImg>
					</StyledWrapperImg>
					<DescriptionWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
					<StyledAuthorName> {singleItemData.authorName}</StyledAuthorName>
					<StyledAuthorCompany> {singleItemData.authorCompany}</StyledAuthorCompany>
				</StyledWrapperSlide>
			</>
		);
	}
}

export default HomeSlide;
