import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
	color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: row;
	font-size: 22px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	justify-content: center;
	line-height: 29px;
	margin: 0 auto;
	max-width: 100%;
	text-align: center;

	&.align-left {
		text-align: left;
		@media(min-width: 1440px) {
			justify-content: flex-start;
			max-width: 50%;
			margin: 0;
		}
	}
	&.smaller-font {
		font-size: 16px;
		line-height: 27px;
		@media(min-width: 768px) {
			font-size: 18px;
			line-height: 37px;
			min-height: unset;
		}
		@media(min-width: 1440px) {
			font-size: 21px; 
		}
	}
	&.min-height-on-mobile {
		min-height: 400px;
		@media(min-width: 768px) {
			min-height: unset;
		}
	}
	@media(min-width: 768px) {
		font-size: 24px;
		line-height: 38px;
		margin-bottom: 30px;
	}
	@media(min-width: 1440px) {
		max-width: 792px;
	}
	span {
		&.pink {
			color: ${({ theme }) => theme.colors.pink};
		}
		&.orange {
			color: ${({ theme }) => theme.colors.orange};
		}
	}
`;
