import React from 'react';
import FooterHeaderDescription from './footerHeaderDescription/FooterHeaderDescription';
import FooterSocialMediaList from './footerSocialMediaList/FooterSocialMediaList';

const ExtendedContentFooter = props => {
	const { data, currentLocale } = props || [];

	if (Array.isArray(data)) {
		return data.map((item, itemKey) => {
			const moduleTypename = item?.__typename || '';
			switch (moduleTypename) {
				case 'DatoCmsModuleFooterHeaderDescription':
					return <FooterHeaderDescription key={itemKey} data={item} />;
				case 'DatoCmsModuleFooterSocialMediaList':
					return <FooterSocialMediaList key={itemKey} data={item} currentLocale={currentLocale} />;

				default:
					return <h1 key={itemKey}>Brak obsługi dla {moduleTypename}</h1>;
			}
		});
	}
	return null;
};

export default ExtendedContentFooter;
