import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PageContainer from '../common/PageContainer';
import { RedactorHeader } from '../common/RedactorHeader';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { toggleHubspotForm } from '../../state/app';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 100px;
	width: 100%;
	@media (min-width: 1440px) {
		margin-top: 160px;
		padding-bottom: 200px;
	}
`;

const IllustrationWrapper = styled.div`
	bottom: -16px;
	height: auto;
	left: 0;
	position: absolute;
	width: 100%;
	z-index: -2;

	@media (min-width: 768px) {
		bottom: -50px;
		width: 110vw;
		left: calc(((100vw - 768px) / -2) - 0px);
		transform: translateX(-70px);
	}
	@media (min-width: 1440px) {
		bottom: -16px;
		width: 100vw;
		left: calc(((100vw - 1440px) / -2) - 0px);
		transform: translateX(0);
	}
	@media (min-width: 1920px) {
		width: 100vw;
	}
	@media (min-width: 2280px) {
		bottom: -85px;
	}
`;

const Illustration = styled.div`
	height: auto;
	max-width: 100%;
`;

const DescriptionWrapper = styled.p`
	color: ${({ theme }) => theme.colors.navyBlue};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 30px;
	margin: 35px auto 0;
	max-width: 540px;
	text-align: center;
	@media (min-width: 1440px) {
		max-width: 590px;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 60px 0;
	width: 100%;
`;

const ButtonWrapper = styled.button`
	background-color: ${({ theme }) => theme.colors.pink};
	border: none;
	border-radius: 29px;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	font-size: 19px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	max-height: 58px;
	padding: 17px 40px 20px 50px;
	position: relative;
	&:before {
		background-color: ${({ theme }) => theme.colors.white};
		border-radius: 50%;
		content: '';
		height: 8px;
		left: 24px;
		position: absolute;
		top: 25px;
		width: 8px;
	}
`;

class HeaderDescriptionButton extends Component {
	handleBtnClick = () => {
		const { dispatch } = this.props;
		dispatch(toggleHubspotForm(true));
		typeof window !== 'undefined' &&
			typeof window.gtag === 'function' &&
			window.gtag('event', 'conversion', { send_to: 'AW-474146236/YmVACKuS5-0BELzLi-IB' });
	};

	render() {
		const { data, elementId, images } = this.props;
		const ModuleData = data?.moduleHeaderDescriptionButton?.[0] || '';
		const header = (ModuleData && ModuleData.header) || '';
		const description = ModuleData?.description || '';
		const buttonText = ModuleData?.textOnButton || '';
		const illustrationEndImg = images?.illustrationEndImg?.childImageSharp?.fluid || '';

		return (
			<PageContainer id={elementId}>
				<ModuleWrapper>
					<IllustrationWrapper>
						<Illustration>
							<Img fluid={illustrationEndImg} imgStyle={{ objectFit: 'unset' }} />
						</Illustration>
					</IllustrationWrapper>
					<RedactorHeader className="bigger pink" dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
					<DescriptionWrapper>{description}</DescriptionWrapper>
					<ButtonContainer>
						<ButtonWrapper onClick={this.handleBtnClick}>{buttonText}</ButtonWrapper>
					</ButtonContainer>
				</ModuleWrapper>
			</PageContainer>
		);
	}
}
const ConnectedHeaderDescriptionButton = connect(
	state => ({
		isHubspotFormVisible: state.app.isHubspotFormVisible,
	}),
	null,
)(HeaderDescriptionButton);

export default props => {
	return (
		<StaticQuery
			query={graphql`
				query {
					illustrationEndImg: file(relativePath: { eq: "illustrationBuildingDrones.jpg" }) {
						childImageSharp {
							fluid(quality: 100, maxWidth: 3000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			`}
			render={data => <ConnectedHeaderDescriptionButton images={data} {...props} />}
		/>
	);
};
