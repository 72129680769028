import React from 'react';
import Helmet from 'react-helmet';

function Seo({ seoData }) {
	const seoTitle = seoData?.title || '';
	const seoDescription = seoData?.description || '';
	const seoTwitterCard = seoData?.twitterCard || '';
	const seoImage = seoData?.image?.url || '';

	return (
		<Helmet
			title={seoTitle}
			meta={[
				{
					name: `description`,
					content: seoDescription,
				},
				{
					property: `og:title`,
					content: seoTitle,
				},
				{
					property: `og:description`,
					content: seoDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: seoImage,
				},
				{
					name: `twitter:card`,
					content: seoTwitterCard,
				},
				{
					name: `twitter:title`,
					content: seoTitle,
				},
				{
					name: `twitter:description`,
					content: seoDescription,
				},
				{
					property: `twitter:image`,
					content: seoImage,
				},
			]}
		/>
	);
}

export default Seo;
