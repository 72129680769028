import React from 'react';
import HeaderAndDescription from './headerAndDescription/HeaderAndDescription';
import HeaderAndImage from './headerAndImage/HeaderAndImage';
import TileListImage from './tileListImage/TileListImage';
import SubscriptionPlan from './subscriptionPlan/SubscriptionPlan';
import HeaderDescriptionButton from './headerDescriptionButton/HeaderDescriptionButton';
import BannerModule from './bannerModule/BannerModule';
import HeaderDescriptionAnimation from './headerDescriptionAnimation/HeaderDescriptionAnimation';
import SliderModule from './sliderModule/SliderModule';
import ImageList from './imageList/ImageList';
import HeaderDescriptionLogo from './headerDescriptionLogo/HeaderDescriptionLogo';
import DropdownList from './dropdownList/DropdownList';
import DescriptionPoint from './descriptionPoint/DescriptionPoint';
import HeaderAndPoints from './headerAndPoints/HeaderAndPoints';
import SliderOpinion from './sliderOpinion/SliderOpinion';

const ExtendedContent = props => {
	const { data, activeSite } = props || [];
	if (Array.isArray(data)) {
		return data.map((item, itemKey) => {
			const moduleTypename = item?.__typename || '';
			switch (moduleTypename) {
				case 'DatoCmsModuleHeaderDescription':
					return <HeaderAndDescription key={itemKey} data={item} />;
				case 'DatoCmsModuleHeaderImage':
					return <HeaderAndImage key={itemKey} data={item} />;
				case 'DatoCmsModuleTileListImage':
					return <TileListImage key={itemKey} data={item} />;
				case 'DatoCmsModuleSubscriptionPlan':
					return <SubscriptionPlan key={itemKey} data={item} activeSite={activeSite} elementId="plans" />;
				case 'DatoCmsModuleBanner':
					return <BannerModule key={itemKey} data={item} activeSite={activeSite} />;
				case 'DatoCmsModuleHeaderDescriptionButton':
					return <HeaderDescriptionButton key={itemKey} data={item} elementId="check-smart-security" />;
				case 'DatoCmsModuleHeaderDescriptionAnimation':
					return <HeaderDescriptionAnimation key={itemKey} data={item} activeSite={activeSite} elementId="smart-security" />;
				case 'DatoCmsModuleSlider':
					return <SliderModule key={itemKey} data={item} activeSite={activeSite} elementId="how-we-do-it" />;
				case 'DatoCmsModuleOpinionClient':
					return <SliderOpinion key={itemKey} data={item} activeSite={activeSite} />;
				case 'DatoCmsModuleImageList':
					return <ImageList key={itemKey} data={item} activeSite={activeSite} elementId="list-with-animation" />;
				case 'DatoCmsModuleHeaderDescriptionLogo':
					return <HeaderDescriptionLogo key={itemKey} data={item} activeSite={activeSite} elementId="technologies" />;
				case 'DatoCmsModuleDropdownList':
					return <DropdownList key={itemKey} data={item} activeSite={activeSite} />;
				case 'DatoCmsModuleDescriptionPoint':
					return <DescriptionPoint key={itemKey} data={item} activeSite={activeSite} />;
				case 'DatoCmsModuleHeaderPointsList':
					return <HeaderAndPoints key={itemKey} data={item} activeSite={activeSite} />;
				default:
					return <h1 key={itemKey}>Brak obsługi dla {moduleTypename}</h1>;
			}
		});
	}
	return null;
};

export default ExtendedContent;
