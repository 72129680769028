import lottie from 'lottie-web';

export const detectReachinfOfModuleById = (id, direction) => {
	const element = document.getElementById(id);
	const elementOffsetTop = element?.offsetTop || '';
	const elementOffsetHeight = element?.offsetHeight || '';
	const windowScrollPosition = window?.scrollY || '';
	const parsedWindowScrollPosition = parseInt(windowScrollPosition, 10);

	if (parsedWindowScrollPosition > elementOffsetTop - elementOffsetHeight) {
		lottie.setDirection(direction, id);
		lottie.play(id);
	}
};
