import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobileOnly, isTablet } from 'react-device-detect';
import PageContainer from '../common/PageContainer';
import ulStyleType from '../../images/ul-style-type.png';
import AnimationComponent from '../common/AnimationComponent';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { detectReachinfOfModuleById } from '../../utils/detectReachingOfModuleById';

const MainWrapper = styled.div`
	color: ${({ theme }) => theme.colors.navyBlue};
	display: flex;
	flex-wrap: wrap;
	@media (min-width: 320px) {
	}
	@media (min-width: 768px) {
		justify-content: space-between;
		align-items: center;
	}
`;

const TextBox = styled.div`
	@media (min-width: 320px) {
		h3 {
			font-size: 16px;
			font-weight: ${({ theme }) => theme.fontWeight.bold};
			margin-bottom: 37px;
			line-height: 38px;
		}
		width: 100%;
		order: 1;
		padding: 50px 0;
	}
	@media (min-width: 768px) {
		h3 {
			font-size: 21px;
			font-weight: ${({ theme }) => theme.fontWeight.bold};
			margin-bottom: 37px;
		}
	}
	@media (min-width: 1440px) {
		width: 50%;
		order: 0;
	}
`;

const StyledList = styled.ul`
	@media (min-width: 320px) {
		line-height: 32px;
	}
	@media (min-width: 768px) {
		line-height: 39px;
	}
`;

const StyledListItems = styled.li`
	display: flex;
	div {
		margin: 6px 6px 0 0;
	}
	@media (min-width: 320px) {
		font-size: 14px;
		line-height: 32px;
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		&:nth-of-type(5) {
			div {
				margin-top: 6px;
				margin-right: 5px;
			}
		}
	}
	@media (min-width: 768px) {
		font-size: 17px;
		line-height: 39px;
		div {
			margin: 9px 6px 0 0;
		}
		&:nth-of-type(5) {
			div {
				margin-top: 9px;
			}
		}
	}
`;

const StyledUlList = styled.div`
	background-image: url(${ulStyleType});
	background-position: left;
	background-repeat: no-repeat;
	background-size: contain;
	height: 21px;
	padding-right: 23px;
	width: auto;
`;

const AnimationContainer = styled.div`
	height: 300px;
	margin: 30px auto 0;
	max-width: 80%;
	@media (min-width: 768px) {
		height: 400px;
	}
	@media (min-width: 1440px) {
		width: 49%;
		height: 560px;
	}
`;

class ImageList extends Component {
	constructor(props) {
		super(props);
		this.moduleActionsOnScroll = this.moduleActionsOnScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.moduleActionsOnScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.moduleActionsOnScroll);
	}

	moduleActionsOnScroll() {
		detectReachinfOfModuleById('list-with-animation', 1);
	}

	render() {
		const { data, elementId } = this.props;
		const moduleData = data?.moduleImageList || '';
		let header = '';
		let animationMobile = '';
		let animationTablet = '';
		let animationDesktop = '';
		const listPoints = [];

		moduleData.forEach(item => {
			if (item.__typename === 'DatoCmsBlockHeader') {
				header = item?.header || '';
			} else if (item.__typename === 'DatoCmsBlockImage') {
				animationMobile = item?.animationMobile?.url || '';
				animationTablet = item?.animationTablet?.url || '';
				animationDesktop = item?.animationDesktop?.url || '';
			} else if (item.__typename === 'DatoCmsBlockListItem') {
				listPoints.push({
					text: item?.text || '',
				});
			}
		});

		const animationSource = (() => {
			if (isMobileOnly) {
				return animationMobile;
			}
			if (isTablet) {
				return animationTablet;
			}
			return animationDesktop;
		})();

		return (
			<PageContainer id={elementId}>
				<MainWrapper>
					<TextBox>
						<h3 dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
						<StyledList>
							{listPoints.map(item => {
								return (
									<StyledListItems key={item.text}>
										<StyledUlList />
										{item.text}
									</StyledListItems>
								);
							})}
						</StyledList>
					</TextBox>
					<AnimationContainer>
						<AnimationComponent
							animationSource={animationSource}
							// animationSource={isMobileOnly ? animationMobile : isTablet ? animationTablet : animationDesktop}
							name="list-with-animation"
						/>
					</AnimationContainer>
				</MainWrapper>
			</PageContainer>
		);
	}
}

export default ImageList;
