import React from 'react';
import styled from 'styled-components';
import shape1 from '../../images/shape1.svg';
import IllustrationImage from '../../images/illustrationDroneBackground.jpg';

const Container = styled.div`
	margin: 0 auto;
	max-width: 100%;
	padding: ${({ theme }) => theme.padding.smallPadding};
	position: relative;
	z-index: 10;
	@media (min-width: 768px) {
		max-width: 768px;
		padding: ${({ theme }) => theme.padding.mediumPadding};
	}
	@media (min-width: 1440px) {
		max-width: 1440px;
		padding: ${({ theme }) => theme.padding.bigPadding};
	}
	&.banner {
		left: 0;
		max-width: 100%;
		padding: ${({ theme }) => theme.padding.smallPadding};
		position: fixed;
		top: 0;
		z-index: -1;
		@media (min-width: 768px) {
			padding: ${({ theme }) => theme.padding.mediumPadding};
		}
		@media (min-width: 1440px) {
			padding: 0 80px;
		}
	}
	&.fixed {
		left: 0;
		margin-bottom: 100vh;
		position: fixed;
		top: 0;
		z-index: -2;
	}
	&.below-banner {
		z-index: 11;
		margin-top: 100vh;
	}
	&.footer {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		@media (max-width: 767px) {
			flex-direction: column;
		}
		@media (max-width: 450px) {
			margin-left: 0;
		}
	}
	&.shape1 {
		position: relative;
		&::after {
			background-image: url(${shape1});
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			content: '';
			height: 317px;
			position: absolute;
			top: 0;
			transform: translate(-540px, -43px);
			width: 833px;
			@media (min-width: 768px) {
				transform: translate(-435px, -17px);
			}
			@media (min-width: 1440px) {
				transform: translate(-303px, 57px);
			}
		}
	}
	&.shape2 {
		position: relative;
		&::after {
			background-image: url(${shape1});
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			content: '';
			height: 317px;
			position: absolute;
			top: 0;
			transform: translate(-540px, -43px) rotate(180deg);
			width: 833px;
			@media (min-width: 1440px) {
				transform: translate(-428px, -79px) rotate(180deg);
			}
		}
	}

	&.illustration {
		padding-bottom: 160px;
		position: relative;
		&::after {
			background-image: url(${IllustrationImage});
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			content: '';
			height: 160px;
			left: 0;
			margin: 0 auto 100%;
			position: absolute;
			right: 0;
			width: 100vw;
			z-index: 1;
			@media (min-width: 600px) {
				height: 220px;
			}
			@media (min-width: 768px) {
				left: calc(((100vw - 768px) / -2) - 0px);
			}
			@media (min-width: 850px) {
				height: 300px;
			}
			@media (min-width: 1150px) {
				height: 360px;
			}
			@media (min-width: 1350px) {
				height: 420px;
			}
			@media (min-width: 1600px) {
				height: 470px;
			}
			@media (min-width: 1800px) {
				height: 520px;
			}
			@media (min-width: 2000px) {
				height: 580px;
			}
			@media (min-width: 2200px) {
				height: 671px;
			}
			@media (min-width: 1440px) {
				left: calc(((100vw - 1440px) / -2) - 0px);
			}
			@media (min-width: 1920px) {
			}
		}
		@media (min-width: 600px) {
			padding-bottom: 220px;
		}
		@media (min-width: 850px) {
			padding-bottom: 300px;
		}
		@media (min-width: 1150px) {
			padding-bottom: 360px;
		}
		@media (min-width: 1350px) {
			padding-bottom: 420px;
		}
		@media (min-width: 1600px) {
			padding-bottom: 470px;
		}
		@media (min-width: 1800px) {
			padding-bottom: 520px;
		}
		@media (min-width: 2000px) {
			padding-bottom: 580px;
		}
		@media (min-width: 2200px) {
			padding-bottom: 620px;
		}
	}
`;

const PageContainer = ({ children, className, id }) => {
	return (
		<Container className={className} id={id}>
			{children}
		</Container>
	);
};

export default PageContainer;
