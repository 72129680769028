import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import PageContainer from '../common/PageContainer';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { RedactorHeader } from '../common/RedactorHeader';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 50px 0 100px;
`;

const ImageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 65px;
	position: relative;
	width: 100%;
`;

const SuperheroWrapper = styled.div`
	display: none;
	height: 327px;
	width: 362px;
	@media (min-width: 768px) {
		display: block;
		position: absolute;
		right: -145px;
		top: -10px;
	}
	@media (min-width: 1440px) {
		width: 476px;
		height: 431px;
		right: -75px;
	}
`;

const FlyingDudeWrapper = styled.div`
	display: none;
	@media (min-width: 768px) {
		display: block;
		position: absolute;
	}
	&.pink {
		left: 25px;
		top: 460px;
		width: 150px;
		@media (min-width: 1440px) {
			width: 180px;
			top: 615px;
			left: 155px;
		}
	}
	&.orange {
		left: -15px;
		top: 125px;
		width: 200px;
		@media (min-width: 1440px) {
			width: 240px;
			top: 175px;
			left: 115px;
		}
	}
`;

const BackgroundWrapper = styled.div`
	height: 561px;
	left: -35px;
	position: absolute;
	top: 195px;
	width: 800px;
	z-index: -1;
	@media (min-width: 768px) {
		width: 1500px;
		height: 1050px;
		left: calc(((100vw - 768px) / -2) - 60px);
		top: 35px;
	}
	@media (min-width: 1440px) {
		width: 1920px;
		height: 1343px;
		left: calc(((100vw - 1440px) / -2) - 180px);
		top: 250px;
	}
	@media (min-width: 1920px) {
		width: 2600px;
		left: calc(((100vw - 1920px) / -2) - 420px);
	}
`;

const Image = styled.div`
	height: 465px;
	width: 290px;
	@media (min-width: 768px) {
		width: 527px;
		height: 769px;
	}
	@media (min-width: 1440px) {
		width: 693px;
		height: 1012px;
	}
`;

const HeaderAndImage = ({ data, images }) => {
	const header = data?.moduleHeaderImage?.[0]?.header || '';
	const image = data?.moduleHeaderImage?.[0]?.image?.fluid || '';
	const imageAlt = data?.moduleHeaderImage?.[0]?.image?.alt || '';
	const superheroImg = images?.superheroImg?.childImageSharp?.fluid || '';
	const flyingDudePinkImg = images?.flyingDudePinkImg?.childImageSharp?.fluid || '';
	const flyingDudeOrangeImg = images?.flyingDudeOrangeImg?.childImageSharp?.fluid || '';
	const backgroundWavesImg = images?.backgroundWavesImg?.childImageSharp?.fluid || '';

	return (
		<PageContainer>
			<ModuleWrapper>
				<RedactorHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
				<ImageWrapper>
					<Image>
						<Img fluid={image} imgStyle={{ objectFit: 'unset' }} alt={imageAlt} />
					</Image>

					<SuperheroWrapper>
						<Img fluid={superheroImg} imgStyle={{ objectFit: 'unset' }} />
					</SuperheroWrapper>
					<FlyingDudeWrapper className="pink">
						<Img fluid={flyingDudePinkImg} imgStyle={{ objectFit: 'unset' }} />
					</FlyingDudeWrapper>
					<FlyingDudeWrapper className="orange">
						<Img fluid={flyingDudeOrangeImg} imgStyle={{ objectFit: 'unset' }} />
					</FlyingDudeWrapper>
					<BackgroundWrapper>
						<Img fluid={backgroundWavesImg} imgStyle={{ objectFit: 'unset' }} />
					</BackgroundWrapper>
				</ImageWrapper>
			</ModuleWrapper>
		</PageContainer>
	);
};

export default props => {
	return (
		<StaticQuery
			query={graphql`
				query {
					superheroImg: file(relativePath: { eq: "ServiceImplementingProcess/superhero-process.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					flyingDudePinkImg: file(relativePath: { eq: "ServiceImplementingProcess/flying-dude-pink.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					flyingDudeOrangeImg: file(relativePath: { eq: "ServiceImplementingProcess/flying-dude-orange.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					backgroundWavesImg: file(relativePath: { eq: "ServiceImplementingProcess/service-implementing-process-bg.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			`}
			render={data => <HeaderAndImage images={data} {...props} />}
		/>
	);
};
