import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Header = styled.h4`
	color: ${({ theme }) => theme.colors.white};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 30px;
	margin-bottom: 14px;
`;

const Description = styled.div`
	color: ${({ theme }) => theme.colors.lightBlue};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 30px;
	text-decoration: none;
	div {
		p {
			a {
				color: ${({ theme }) => theme.colors.lightBlue};
				text-decoration: none;
			}
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media (max-width: 1439px) {
		flex-wrap: wrap;
	}
	@media (max-width: 450px) {
		flex-direction: column;
	}
`;

const WrapperBox = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 45px;
	@media (max-width: 1439px) {
		width: 50%;
		padding-right: 0px;
		padding-bottom: 34px;
		&:nth-child(3) {
			padding-bottom: 0;
		}
		&:nth-child(4) {
			padding-bottom: 0;
		}
	}
	@media (max-width: 450px) {
		width: 100%;
		&:nth-child(3) {
			padding-bottom: 34px;
		}
	}
`;

const FooterHeaderDescription = ({ data }) => {
	const moduleData = data?.moduleFooterHeaderDescription || '';
	let mainHeader = '';
	let mainDescription = '';

	const handleBtnClick = e => {
		if (e.target.href && e.target.href.includes('mailto')) {
			typeof window !== 'undefined' &&
				typeof window.gtag === 'function' &&
				window.gtag('event', 'conversion', { send_to: 'AW-474146236/YmVACKuS5-0BELzLi-IB' });
		}
	};

	return (
		<Wrapper>
			{moduleData.map((item, key) => {
				mainDescription = item?.description || '';
				mainHeader = item?.header || '';
				return (
					<React.Fragment key={key}>
						<WrapperBox>
							<Header>{mainHeader}</Header>
							<Description
								onClick={e => handleBtnClick(e)}
								dangerouslySetInnerHTML={getHtmlFromRedactorField(mainDescription)}
							/>
						</WrapperBox>
					</React.Fragment>
				);
			})}
		</Wrapper>
	);
};

export default FooterHeaderDescription;
