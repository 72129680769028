import React from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const ModuleWrapper = styled.div`
	color: ${({ theme }) => theme.colors.navyBlue};
	margin-top: 50px;
	padding-bottom: 50px;
	@media (min-width: 1440px) {
		padding-bottom: 100px;
	}
`;

const Header = styled.div`
	h2 {
		font-weight: bold;
	}
	h3 {
		font-weight: lighter;
	}
	@media (min-width: 320px) {
		h2,
		h3 {
			font-size: 30px;
		}
		margin-bottom: 28px;
	}
	@media (min-width: 768px) {
		h2,
		h3 {
			font-size: 40px;
		}
		margin-bottom: 0;
		line-height: 48px;
	}
	@media (min-width: 1440px) {
		margin-bottom: 36px;
	}
`;

const Description = styled.div`
	@media (min-width: 320px) {
		font-size: 16px;
		line-height: 27px;
	}
	@media (min-width: 768px) {
		font-weight: normal;
		line-height: 30px;
	}
	@media (min-width: 1440px) {
		font-size: 21px;
		line-height: 37px;
	}
`;

const HeaderAndDescription = ({ data }) => {
	const header = data?.moduleHeaderDescription?.[0]?.header || '';
	const description = data?.moduleHeaderDescription?.[0]?.description || '';

	return (
		<PageContainer>
			<ModuleWrapper>
				<Header>
					<h2 dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
				</Header>
				<Description>
					<p>{description}</p>
				</Description>
			</ModuleWrapper>
		</PageContainer>
	);
};
export default HeaderAndDescription;
