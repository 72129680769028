import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick/lib';
import PageContainer from '../common/PageContainer';
import ArrowRight from '../../images/Slider/arrow-right.svg';
import ArrowLeft from '../../images/Slider/arrow-left.svg';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import HomeSlide from './HomeSlide';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 50px;
	position: relative;
	width: 100%;
`;
const MainHeaderWrapper = styled.div`
	color: #191c63;
	font-size: 45px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	line-height: 45px;
	margin: 36px 0;
	strong {
		font-weight: ${({ theme }) => theme.fontWeight.extraBold};
	}
	@media (max-width: 350px) {
		font-size: 38px;
	}
	@media (min-width: 768px) {
		font-size: 60px;
		line-height: 60px;
		margin: 32px 0;
	}
	@media (min-width: 1440px) {
		font-size: 76px;
		line-height: 71px;
		margin: 40px 0;
	}
`;

const SliderWrapper = styled.div`
	height: 267px;
	margin: 0 auto;
	width: 100%;
	@media (min-width: 220px) {
		width: 200px;
		height: 398px;
	}
	@media (min-width: 320px) {
		width: 250px;
		height: 398px;
	}
	@media (min-width: 375px) {
		width: 315px;
		height: 398px;
	}
	@media (min-width: 500px) {
		width: 430px;
		height: 440px;
	}
	@media (min-width: 580px) {
		width: 522px;
		height: 400px;
	}
	@media (min-width: 1440px) {
		width: 936px;
		height: 550px;
	}
	iframe {
		html {
			body {
				div.ytp-cued-thumbnail-overlay-image {
					width: 101% !important;
				}
			}
		}
	}
`;

const ArrowWrapper = styled.img`
	cursor: pointer;
	width: 20px;
	@media (min-width: 768px) {
		display: none;
	}
	&.around-slide-left {
		display: none;
		@media (min-width: 768px) {
			position: absolute;
			display: block;
			left: 20px;
			top: 375px;
		}
		@media (min-width: 1440px) {
			top: 470px;
		}
	}
	&.around-slide-right {
		display: none;
		@media (min-width: 768px) {
			position: absolute;
			display: block;
			right: 20px;
			top: 375px;
		}
		@media (min-width: 1440px) {
			top: 470px;
		}
	}
`;

class SliderOpinion extends Component {
	next() {
		this.slider.slickNext();
	}

	previous() {
		this.slider.slickPrev();
	}

	render() {
		const { data } = this.props;
		const moduleData = data?.slideOpinion || [];
		const header = data?.header || '';
		const LogoMale = data?.maleAvatar?.fluid;
		const LogoFemale = data?.femaleAvatar?.fluid;

		const settings = {
			dots: false,
			infinite: true,
			arrows: false,
			draggable: false,
			swipe: true,
			slidesToShow: 1,
			initialSlide: 0,
			slidesToScroll: 1,
			centerPadding: 0,
			autoplay: true,
			autoplaySpeed: 5000,
			useTransform: false,
		};

		return (
			<PageContainer>
				<ModuleWrapper>
					<ArrowWrapper className="around-slide-left" src={ArrowLeft} onClick={this.previous.bind(this)} />
					<ArrowWrapper className="around-slide-right" src={ArrowRight} onClick={this.next.bind(this)} />
					<MainHeaderWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
					<SliderWrapper>
						<Slider ref={slide => (this.slider = slide)} {...settings}>
							{moduleData.map(item => {
								return <HomeSlide key={item} singleItemData={item} logoMale={LogoMale} logoFemale={LogoFemale} />;
							})}
						</Slider>
					</SliderWrapper>
				</ModuleWrapper>
			</PageContainer>
		);
	}
}

export default SliderOpinion;
