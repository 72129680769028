import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PageContainer from '../common/PageContainer';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { DescriptionWrapper } from '../common/RedactorDescription';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 30px 0 100px;
	width: 100%;
`;

const PointsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 auto;
	width: 100%;
	div {
		&:first-child {
			margin-top: 0;
			@media (min-width: 768px) {
				margin-top: -30px;
			}
		}
	}
	@media (min-width: 1440px) {
		padding: 0 50px;
	}
`;

const SinglePointWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: -30px;
	width: 100%;
	@media (min-width: 768px) {
		width: 50%;
	}
	@media (min-width: 1440px) {
		width: 33%;
	}
`;

const DotImage = styled.div`
	margin: 0 auto;
	width: 215px;
`;

const PointText = styled.p`
	color: ${({ theme }) => theme.colors.white};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 20px;
	margin: -60px auto 0;
	max-width: 293px;
	text-align: center;
	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 30px;
	}
	@media (min-width: 1440px) {
		max-width: 225px;
	}
`;

const DescriptionPoint = ({ data, images }) => {
	const ModuleData = data?.moduleDescriptionPoint || '';
	let description;
	const pointsArray = [];
	const dotImg = images?.dotImg?.childImageSharp?.fluid || '';

	ModuleData.map(item => {
		if (item.__typename === 'DatoCmsBlockDescription') {
			description = item?.text || '';
		}
		if (item.__typename === 'DatoCmsBlockPlanPoint') {
			pointsArray.push({
				text: item?.point || '',
			});
		}
	});

	return (
		<PageContainer>
			<ModuleWrapper>
				<DescriptionWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
				<PointsWrapper>
					{pointsArray.map(item => {
						const { text } = item;
						return (
							<SinglePointWrapper key={text}>
								<DotImage>
									<Img fluid={dotImg} imgStyle={{ objectFit: 'unset' }} />
								</DotImage>
								<PointText>{text}</PointText>
							</SinglePointWrapper>
						);
					})}
				</PointsWrapper>
			</ModuleWrapper>
		</PageContainer>
	);
};

export default props => {
	return (
		<StaticQuery
			query={graphql`
				query {
					dotImg: file(relativePath: { eq: "dot-with-shadow.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			`}
			render={data => <DescriptionPoint images={data} {...props} />}
		/>
	);
};
