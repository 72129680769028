import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PageContainer from '../common/PageContainer';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { RedactorHeader } from '../common/RedactorHeader';
import { DescriptionWrapper } from '../common/RedactorDescription';
import { detectReachinfOfModuleById } from '../../utils/detectReachingOfModuleById';
import { movePageToTop } from '../../utils/movePageToTop';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 50px 0 500px;
	width: 100%;
	@media (min-width: 768px) {
		margin: 100px 0 0;
	}
`;

const BackgroundContainer = styled.div`
	height: 1050px;
	left: 0;
	position: absolute;
	top: -100px;
	width: 100%;
	z-index: -2;
	@media (min-width: 768px) {
		height: 1253px;
		width: 100vw;
		top: -30px;
		left: calc((100vw - 768px) / -2);
	}
	@media (min-width: 1440px) {
		height: 1185px;
		width: 100vw;
		top: -50px;
		left: calc((100vw - 1440px) / -2);
	}
	&.mobile {
		display: block;
		@media (min-width: 1440px) {
			display: none;
		}
	}
	&.desktop {
		display: none;
		@media (min-width: 1440px) {
			display: block;
		}
	}
	.gatsby-image-wrapper {
		height: inherit;
		width: 100vw;
	}
`;

const AnimationContainer = styled.div`
	bottom: -350px;
	left: calc((100vw - 353px) / 2);
	margin-left: 15px;
	position: absolute;
	width: 403px;
	z-index: 0;
	@media (min-width: 768px) {
		position: relative;
		width: 633px;
		margin: -15px 0 0 30px;
		bottom: 0;
		left: 0;
	}
	@media (min-width: 1440px) {
		width: 989px;
		margin: -250px 0 40px 100px;
	}
`;

const MoveTopPageArrowContainer = styled.div`
	bottom: -10px;
	cursor: pointer;
	height: 85px;
	opacity: 0;
	position: fixed;
	right: 65px;
	transition: opacity 0.3s ease-in-out;
	width: 82px;
	&.show {
		opacity: 1;
	}
`;

const ImageWrapper = styled.div`
	width: 320px;
	@media (min-width: 768px) {
		width: 632px;
	}
	@media (min-width: 1440px) {
		width: 990px;
	}

	.gatsby-image-wrapper {
		height: 100%;
		width: inherit;
	}
`;

class HeaderDescriptionAnimation extends Component {
	state = {
		showArrowUp: false,
	};

	constructor(props) {
		super(props);
		this.moduleActionsOnScroll = this.moduleActionsOnScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.moduleActionsOnScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.moduleActionsOnScroll);
	}

	showArrowUp() {
		if (window.scrollY > 200) {
			this.setState({ showArrowUp: true });
		} else {
			this.setState({ showArrowUp: false });
		}
	}

	moduleActionsOnScroll() {
		detectReachinfOfModuleById('smart-security', 1);
		this.showArrowUp();
	}

	render() {
		const { showArrowUp } = this.state;
		const { data, elementId, images } = this.props;
		const moduleData = data?.moduleHeaderDescriptionAnimation?.[0] || '';
		const header = moduleData?.header || '';
		const description = moduleData?.description || '';
		const gradientBackgroundTablet = images?.gradientBackgroundTablet?.childImageSharp?.fluid || '';
		const gradientBackgroundDesktop = images?.gradientBackgroundDesktop?.childImageSharp?.fluid || '';
		const arrowMoveTopImg = images?.arrowMoveTopImg?.childImageSharp?.fluid || '';

		const smartProcessImgSources = [
			images?.smartProcessMobileImg?.childImageSharp?.fixed,
			{
				...images.smartProcessLargeMobileImg.childImageSharp.fixed,
				media: `(min-width: 768px) and (max-width: 1439px)`,
			},
			{
				...images?.smartProcessImg?.childImageSharp?.fixed,
				media: `(min-width: 1440px)`,
			},
		];

		return (
			<PageContainer className="below-banner" id={elementId}>
				<BackgroundContainer className="mobile">
					<Img fluid={gradientBackgroundTablet} imgStyle={{ objectFit: 'unset' }} />
				</BackgroundContainer>
				<BackgroundContainer className="desktop">
					<Img fluid={gradientBackgroundDesktop} imgStyle={{ objectFit: 'unset' }} />
				</BackgroundContainer>
				<ModuleWrapper>
					<RedactorHeader
						className="bigger margin-bottom margin-top"
						dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}
					/>
					<DescriptionWrapper
						className="align-left smaller-font min-height-on-mobile"
						dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}
					/>
				</ModuleWrapper>
				<AnimationContainer>
					<ImageWrapper>
						<Img fixed={smartProcessImgSources} imgStyle={{ objectFit: 'contain' }} />
					</ImageWrapper>
					{/* <AnimationComponent animationSource={animationSource} name={elementId} /> */}
				</AnimationContainer>
				<MoveTopPageArrowContainer className={showArrowUp ? 'show' : ''} onClick={() => movePageToTop()}>
					<Img fluid={arrowMoveTopImg} imgStyle={{ objectFit: 'unset' }} />
				</MoveTopPageArrowContainer>
			</PageContainer>
		);
	}
}

export default props => {
	return (
		<StaticQuery
			query={graphql`
				query {
					gradientBackgroundTablet: file(relativePath: { eq: "BelowBaner/gradient-background-tablet.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					gradientBackgroundDesktop: file(relativePath: { eq: "BelowBaner/gradient-background-desktop.png" }) {
						childImageSharp {
							fluid(quality: 100, maxHeight: 1185) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					arrowMoveTopImg: file(relativePath: { eq: "up_arrow.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					smartProcessMobileImg: file(relativePath: { eq: "BelowBaner/smart-mobile.png" }) {
						childImageSharp {
							fixed(quality: 60, width: 320, height: 328) {
								...GatsbyImageSharpFixed_noBase64
							}
						}
					}
					smartProcessLargeMobileImg: file(relativePath: { eq: "BelowBaner/smart-mobile.png" }) {
						childImageSharp {
							fixed(quality: 60, width: 632, height: 648) {
								...GatsbyImageSharpFixed_noBase64
							}
						}
					}
					smartProcessImg: file(relativePath: { eq: "BelowBaner/smart-process.png" }) {
						childImageSharp {
							fixed(quality: 60, width: 990, height: 926) {
								...GatsbyImageSharpFixed_noBase64
							}
						}
					}
				}
			`}
			render={data => <HeaderDescriptionAnimation images={data} {...props} />}
		/>
	);
};
