import React, { Component } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';
import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { RedactorHeader } from '../common/RedactorHeader';
import PageContainer from '../common/PageContainer';
import AnimationComponent from '../common/AnimationComponent';
import Superhero from '../../animations/superhero';
import Landing from '../../animations/landing';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-start;
	max-width: 100%;
	overflow: hidden;
	padding-top: 150px;
	width: 100vw;
	z-index: 10;
	@media (max-height: 667px) and (max-width: 375px) {
		padding-top: 95px;
	}
	@media (min-height: 800px) {
		padding-top: 200px;
	}
	@media (min-width: 768px) {
		padding-top: 180px;
	}
	@media (min-width: 1440px) {
		justify-content: center;
		height: 100vh;
		margin: 0;
		padding: 0;
	}
`;

const Description = styled.div`
	color: ${({ theme }) => theme.colors.white};
	display: block;
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 24px;
	margin-top: 25px;
	text-align: right;
	@media (max-width: 374px) and (max-height: 639px) {
		display: none;
	}
	@media (max-height: 667px) and (max-width: 375px) {
		margin-top: 16px;
	}
	@media (min-width: 768px) {
		font-size: 21px;
		line-height: 31px;
	}
	@media (width: 1024px) and (height: 1366px) {
		font-size: 36px;
		line-height: 60px;
	}
	@media (min-width: 2200px) {
		font-size: 30px;
		line-height: 50px;
	}
	@media (min-width: 2500px) {
		font-size: 36px;
		line-height: 60px;
	}

	span.grey-background {
		background-image: linear-gradient(-180deg, transparent 7%, rgba(25, 28, 99, 0.34) 0);
		background-position: right;
		background-repeat: no-repeat;
		background-size: 100%;
		margin-bottom: 5px;
		padding: 2px 15px;
		text-decoration: none;
	}
`;

const AnimationContainer = styled.div`
	position: absolute;
	left: -40px;
	bottom: -10px;
	width: 1313px;
	z-index: -3;

	&.land {
		z-index: -2 !important;
	}
	@media (max-height: 667px) and (max-width: 375px) {
		bottom: -70px;
	}
	@media (min-width: 768px) {
		width: 1826px;
		//left: calc(((100vw - 768px) / -2) - 45px); // (szerokosc ekranu - szerokosc page containera) / 2 minus o ile ma wystawać animacja
		left: -54px;
	}
	@media (min-width: 1440px) {
		/* width: 2825px;
      	left: -84px;
      	bottom: -200px; */
		width: 2560px;
		left: -69px;
		bottom: -115px;
	}
`;

const SuperheroContainer = styled.div`
	bottom: -10px;
	left: 90px;
	position: absolute;
	width: 370px;
	z-index: -1;
	/* transform:scale(0.7);//test img */
	@media (min-width: 768px) {
		width: 415px;
		left: 140px;
		bottom: 70px;
		/* bottom: 26px;//test img */
	}
	@media (min-width: 1440px) {
		width: 515px;
		left: 252px;
		bottom: -40px;
		/* transform:scale(1);//test img */
	}
	@media (min-width: 2200px) {
		width: 800px;
		left: 245px;
		bottom: -40px;
		/* transform:scale(1);//test img */
	}
	@media (min-width: 2500px) {
		width: 1000px;
		left: 245px;
		bottom: -60px;
		/* transform:scale(1);//test img */
	}
`;

const SuperheroShadowContainer = styled.div`
	//width: 376px;
	bottom: 30px;
	height: 56px;
	opacity: 0.6;
	position: absolute;
	width: 193px;
	@media (min-width: 768px) {
		width: 500px;
	}
	@media (min-width: 1440px) {
		width: 808px;
		height: 120px;
	}

	.gatsby-image-wrapper {
		height: 100%;
		width: 100%;
	}
`;

const CharacterShadowContainer = styled.div`
	bottom: 50px;
	height: 71px;
	left: 120px;
	position: absolute;
	width: 205px;
	z-index: -1;
	@media (min-width: 768px) {
		width: 285px;
		height: 98px;
		bottom: 60px;
		left: 170px;
	}
	@media (min-width: 1440px) {
		width: 441px;
		height: 152px;
		bottom: 60px;
		left: 170px;
	}
	@media (min-width: 2200px) {
		width: 653px;
		height: 149px;
		bottom: 87px;
		left: 300px;
	}
	@media (min-width: 2500px) {
		width: 700px;
		height: 152px;
		bottom: 111px;
		left: 386px;
	}
`;

class BannerModule extends Component {
	componentDidMount() {
		setTimeout(() => lottie.play('superhero'), 500);
		setTimeout(() => lottie.play('landing'), 500);
	}

	render() {
		const { data, images } = this.props;
		const bannerData = (data && data.moduleBanner && data.moduleBanner[0]) || '';
		const header = bannerData?.header || '';
		const description = bannerData.description || '';
		const superheroShadow = images?.superheroShadow?.childImageSharp?.fluid || '';
		const characterShadow = images?.characterShadow?.childImageSharp?.fluid || '';
		const illustrationBg = bannerData?.image?.fluid || '';
		const illustrationBgAlt = bannerData?.image?.alt || '';

		return (
			<>
				<PageContainer className="banner" id="banner">
					<AnimationContainer className="land">
						<AnimationComponent animationData={Landing} name="landing" isPlayedOnPageLoad />
					</AnimationContainer>
					<AnimationContainer>
						<Img fluid={illustrationBg} alt={illustrationBgAlt} imgStyle={{ objectFit: 'unset' }} />
					</AnimationContainer>

					<SuperheroContainer>
						<AnimationComponent animationData={Superhero} name="superhero" isPlayedOnPageLoad />
						<SuperheroShadowContainer>
							<Img fluid={superheroShadow} imgStyle={{ objectFit: 'unset' }} />
						</SuperheroShadowContainer>
						<CharacterShadowContainer>
							<Img fluid={characterShadow} imgStyle={{ objectFit: 'contain' }} />
						</CharacterShadowContainer>
					</SuperheroContainer>
					<ModuleWrapper>
						<RedactorHeader className="banner navy-blue" dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
						<Description dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
					</ModuleWrapper>
				</PageContainer>
			</>
		);
	}
}

export default props => {
	return (
		<StaticQuery
			query={graphql`
				query {
					superheroShadow: file(relativePath: { eq: "Banner/superhero-shadow.png" }) {
						childImageSharp {
							fluid(quality: 100, maxHeight: 56) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					characterShadow: file(relativePath: { eq: "Banner/character-shadow.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			`}
			render={data => <BannerModule images={data} {...props} />}
		/>
	);
};
