import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PageContainer from '../common/PageContainer';
import { RedactorHeader } from '../common/RedactorHeader';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import ArrowRight from '../../images/Slider/arrow-right.svg';
import ArrowLeft from '../../images/Slider/arrow-left.svg';
import CounterShadow from '../../images/Slider/counter-shadow.svg';
import BackgroundAfter2 from '../../images/Slider/background-after-v2.svg';
import BackgroundBefore from '../../images/Slider/background-before.svg';
import BackgroundBeforeMobile from '../../images/Slider/background-before-mobile.svg';
import VideoModule from './VideoModule';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	width: 100%;
`;

const BackgroundContainer = styled.div`
	background: transparent linear-gradient(360deg, #ff2bf1 0%, #191c63 41%, #252a88 73%, #191c63 100%) 0 0 no-repeat;
	height: 2020px;
	left: -960px;
	position: absolute;
	top: 350px;
	width: 2400px;
	z-index: -1;
	@media (min-width: 375px) {
		left: -960px;
		height: 2000px;
	}
	@media (min-width: 425px) {
		height: 1950px;
	}
	@media (min-width: 550px) {
		left: -830px;
	}
	@media (min-width: 700px) {
		left: -830px;
		height: 2000px;
	}
	@media (min-width: 768px) {
		height: 2600px;
		left: -810px;
	}
	@media (min-width: 1440px) {
		width: 3300px;
		left: -920px;
		top: 350px;
		height: 2920px;
	}
	@media (min-width: 1920px) {
		width: 4250px;
		left: -1400px;
	}
	&::before {
		background-image: url(${BackgroundBeforeMobile});
		background-position: center;
		background-repeat: no-repeat;
		content: '';
		height: 200px;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		top: -7%;
		width: auto;
		z-index: -3;
		@media (min-width: 320px) {
			transform: scale(0.5);
		}
		@media (min-width: 550px) {
			top: -6%;
		}
		@media (min-width: 768px) {
			background-image: url(${BackgroundBefore});
			transform: scale(1);
			top: -1%;
		}
		@media (min-width: 1200px) {
			top: -2%;
		}
		@media (min-width: 1600px) {
			top: -1%;
			transform: scale(1.5);
		}
	}
	&::after {
		background-image: url(${BackgroundAfter2});
		background-position: center;
		background-repeat: no-repeat;
		bottom: -200px;
		content: '';
		height: 200px;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		transform: rotate(180deg);
		width: 2550px;
		@media (min-width: 320px) {
			transform: rotate(180deg) scale(0.3);
			left: -5%;
			bottom: -129px;
		}
		@media (min-width: 450px) {
			bottom: -129px;
			left: -3%;
		}
		@media (min-width: 550px) {
			transform: rotate(180deg) scale(0.5);
			bottom: -149px;
			left: -6%;
		}
		@media (min-width: 650px) {
			left: -3%;
		}
		@media (min-width: 768px) {
			bottom: -199px;
			transform: rotate(180deg) scale(1);
			left: 0;
		}
		@media (min-width: 2500px) {
			transform: rotate(180deg) scale(1.02);
		}
	}
`;
const BuildingContainer = styled.div`
	height: 467px;
	left: 50px;
	position: absolute;
	top: -110px;
	width: 717px;
	z-index: -2;
	@media (min-width: 768px) {
		width: 725px;
		height: 491px;
		top: 0;
		left: 20px;
	}
	@media (min-width: 1440px) {
		right: 86px;
		left: unset;
		top: -20px;
	}
`;
const NavyDudeContainer = styled.div`
	height: 97px;
	left: 300px;
	position: absolute;
	top: -80px;
	width: 95px;
	@media (min-width: 768px) {
		width: 160px;
		height: 164px;
		left: 450px;
		top: 5px;
	}
	@media (min-width: 1440px) {
		top: -15px;
		left: 1020px;
	}
`;
const SliderWrapper = styled.div`
	height: 367px;
	margin: 0 auto;
	width: 100%;
	@media (min-width: 375px) {
		width: 315px;
		height: 398px;
	}
	@media (min-width: 500px) {
		width: 430px;
		height: 440px;
	}
	@media (min-width: 580px) {
		width: 522px;
		height: 500px;
	}
	@media (min-width: 1440px) {
		width: 936px;
		height: 708px;
	}
	iframe {
		html {
			body {
				div.ytp-cued-thumbnail-overlay-image {
					width: 101% !important;
				}
			}
		}
	}
`;

const ArrowsAndCounterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 45px 0 20px;
	padding: 0 20px;
	width: 100%;
	@media (min-width: 768px) {
		justify-content: center;
		margin: 20px 0 50px;
	}
	@media (min-width: 1440px) {
		margin: -40px 0 70px;
	}
`;

const ArrowWrapper = styled.img`
	cursor: pointer;
	width: 20px;
	@media (min-width: 768px) {
		display: none;
	}
	&.around-slide-left {
		display: none;
		@media (min-width: 768px) {
			position: absolute;
			display: block;
			left: 20px;
			top: 375px;
		}
		@media (min-width: 1440px) {
			top: 470px;
		}
	}
	&.around-slide-right {
		display: none;
		@media (min-width: 768px) {
			position: absolute;
			display: block;
			right: 20px;
			top: 375px;
		}
		@media (min-width: 1440px) {
			top: 470px;
		}
	}
`;
const CounterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
`;

const CounterText = styled.p`
	color: ${({ theme }) => theme.colors.white};
	font-size: 33px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 47px;
`;

const ShadowImage = styled.img`
	left: -31px;
	position: absolute;
	width: 114px;
`;
const BottomShadowWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: -25px;
	width: 100%;
	@media (min-width: 768px) {
		margin-top: -30px;
	}
`;
const BottomShadow = styled.div`
	height: 134px;
	margin: 0 auto;
	width: 427px;
	.gatsby-image-wrapper {
		height: 100%;
		width: 100%;
	}
	@media (min-width: 768px) {
		width: 570px;
		height: 134px;
	}
	@media (min-width: 1440px) {
		width: 713px;
		height: 134px;
	}
`;

class SliderModule extends Component {
	state = {
		currentSlideId: 1,
		numberOfSlides: 0,
	};

	next() {
		if (this.state.currentSlideId < this.state.numberOfSlides) {
			this.setState({ currentSlideId: this.state.currentSlideId + 1 });
		} else if (this.state.currentSlideId === this.state.numberOfSlides) {
			this.setState({ currentSlideId: 1 });
		}
	}

	previous() {
		if (this.state.currentSlideId > 1) {
			this.setState({ currentSlideId: this.state.currentSlideId - 1 });
		} else if (this.state.currentSlideId === 1) {
			this.setState({ currentSlideId: this.state.numberOfSlides });
		}
	}

	render() {
		const DataFromCraft = this.props.data;
		const { elementId, images } = this.props;
		const ModuleData = (DataFromCraft && DataFromCraft.moduleSlider) || '';
		const { currentSlideId } = this.state;
		let header = '';
		const slidesArray = [];
		const buildingImg = images?.buildingImg?.childImageSharp?.fluid || '';
		const navyDudeWithStarsImg = images?.navyDudeWithStarsImg?.childImageSharp?.fluid || '';
		const sliderBottomShadowImg = images?.sliderBottomShadowImg?.childImageSharp?.fluid || '';

		ModuleData.forEach(item => {
			if (item.__typename === 'DatoCmsBlockHeader') {
				header = (item && item.header) || '';
			} else if (item.__typename === 'DatoCmsBlockSlide') {
				slidesArray.push({
					id: slidesArray.length + 1,
					url: (item && item.url) || '',
				});
			}
		});

		this.state.numberOfSlides = slidesArray.length;

		return (
			<PageContainer id={elementId}>
				<BackgroundContainer />
				<BuildingContainer>
					<Img fluid={buildingImg} imgStyle={{ objectFit: 'unset' }} />
				</BuildingContainer>
				<NavyDudeContainer>
					<Img fluid={navyDudeWithStarsImg} imgStyle={{ objectFit: 'unset' }} />
				</NavyDudeContainer>
				<ModuleWrapper>
					<RedactorHeader className="bigger" dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
					<ArrowWrapper className="around-slide-left" src={ArrowLeft} onClick={this.previous.bind(this)} />
					<ArrowWrapper className="around-slide-right" src={ArrowRight} onClick={this.next.bind(this)} />
					<SliderWrapper>
						<ArrowsAndCounterWrapper>
							<ArrowWrapper src={ArrowLeft} onClick={this.previous.bind(this)} />
							<CounterWrapper>
								<ShadowImage src={CounterShadow} />
								<CounterText>
									{currentSlideId}/{slidesArray.length}
								</CounterText>
							</CounterWrapper>
							<ArrowWrapper src={ArrowRight} onClick={this.next.bind(this)} />
						</ArrowsAndCounterWrapper>
						{slidesArray.map((item, key) => {
							const videoUrl = item.url;
							const newKey = key;

							return (
								<React.Fragment key={key}>
									<VideoModule newKey={newKey} videoUrl={videoUrl} paused={this.state.currentSlideId !== newKey + 1} />
								</React.Fragment>
							);
						})}
						<BottomShadowWrapper>
							<BottomShadow>
								<Img fluid={sliderBottomShadowImg} imgStyle={{ objectFit: 'unset' }} />
							</BottomShadow>
						</BottomShadowWrapper>
					</SliderWrapper>
				</ModuleWrapper>
			</PageContainer>
		);
	}
}

export default props => {
	return (
		<StaticQuery
			query={graphql`
				query {
					buildingImg: file(relativePath: { eq: "Slider/buildings.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					navyDudeWithStarsImg: file(relativePath: { eq: "Slider/navy-dude-with-stars.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
					sliderBottomShadowImg: file(relativePath: { eq: "Slider/slider-bottom-shadow.png" }) {
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			`}
			render={data => <SliderModule images={data} {...props} />}
		/>
	);
};
